<template>
    <div class="container-fluid JHeader JHeader--fixed">
        <div class="container">
            <header class="JHeader-content">
                <!-- <a href="javascript:void(0)" id="JMenu_btn_open" @click="openHeader()">
                    <span class="fas fa-bars JMenu_btn-slider"></span>
                </a> -->
                <!--
                <a href="/" class="JHeader-icon">
                    <img src="/images/icon.png">
                </a>
                -->
                <div class="JSearch-container">
                    <div class="JSearch-result" v-if="dataSearch.length > 0">
                        <router-link :to="`/${user._id}`" class="JSearch-result-item" v-for="(user, index) in dataSearch"
                            :key="index">
                            <div class="JSearch-result-icon">
                                <img :src="'https://avatars.dicebear.com/api/identicon/:' + user._id + '.svg'">
                            </div>
                            <div class="JSearch-result-text-content">
                                <div class="JSearch-result-text">
                                    {{ user.fullname }}
                                </div>
                                <div class="JSearch-result-type">
                                    Persona
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <nav class="JNav" style="margin-right: 95%;">

                    <ul v-if="users == null" class="JNav_Menu">
                        <li class="JNav_Menu-item">
                            <button class="J-btn  J-btn-bars white" @click="toggleSidebar">
                                <i class="fas fa-bars"></i>
                            </button>
                        </li>
                        <li>
                            <button class="JNav_Menu-item-img">
                                <img src="/images/logo.png" alt="">
                            </button>
                        </li>
                    </ul>
                    <ul v-else class="JNav_Menu">


                        <li class="JNav_Menu-item">
                            <router-link to="/" class="JNav_Menu-item-link">
                                <span class="fas fa-home"></span>
                            </router-link>
                        </li>

                    </ul>
                </nav>
            </header>
        </div>


    </div>
</template>

<script>
// import {UsersStorage,WebStorage} from '@/helpers/storage'
import { collapsed, toggleSidebar, sidebarWidth } from "../sidebar/state";
export default {
    data: () => (
        {
            search: '',

            users: null,
            dataSearch: [],
            pagination: {
                current: 0,
                page: 1,
                pages: 1,
            },
            load: false,
        }
    ),
    setup() {
        return { collapsed, toggleSidebar, sidebarWidth };
    },
    computed: {

    },
    mounted() {
        // this.users=this.usersStorage.get()
    },
    methods: {

    }

}
</script>
