class Storage{
    constructor(name=''){
        this.name=name;
    }
    get(){
        return JSON.parse(localStorage.getItem(this.name))||localStorage.getItem(this.name);
    }
    set(data){
        var d=JSON.stringify(data)||data;
        localStorage.setItem(this.name,d);
    }
    remove(){
        localStorage.removeItem(this.name);
    }
    static clear(){
        localStorage.clear();
    }
}

export class RoutesStorage extends Storage{
    constructor(){
        super('routes');
    }
}

export class UsersStorage extends Storage{
    constructor(){
        super('user');
    }
}

export class WebStorage extends Storage{
    constructor(){
        super('web');
    }
}

export class CouponStorage extends Storage{
    constructor(){
        super('coupon');
    }
}


export class SearchListStorage extends Storage{
    constructor(){
        super('searchList');
    }
}