import { createRouter, createWebHistory } from "vue-router";

import Applayouts from "../components/layouts/app.layouts";
import Dashboardlayouts from "../components/layouts/dashboard.layouts";
const routes = [
  {
    path: "/",
    name: "Home Page",
    component: Applayouts,
    meta: {
      required: false,
    },
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../pages/Home.page"),
        meta: {
          required: false,
        },
      },

      {
        path: "/avisodeprivacidad",
        name: "AvisoDePrivacidad",
        component: () => import("../pages/AvisoDePrivacidad.page"),
        meta: {
          required: false,
        },
      },

      {
        path: "/:company",
        name: "company-list",
        component: () => import("../pages/Register.page"),
        meta: {
          required: false,
        },
      },
      {
        path: "/create-company/:token",
        name: "create-company",
        component: () => import("../pages/admin/Create.page"),
        meta: {
          required: false,
        },
      },
      {
        path: "/login",
        name: "/login",
        component: () => import("../pages/Login.page"),
        meta: {
          required: false,
        },
      },
      {
        path: "/recuva",
        name: "/recuva",
        component: () => import("../pages/Recuva.page"),
        meta: {
          required: false,
        },
      },
      {
        path: "/password/validate",
        name: "/password",
        component: () => import("../pages/Password.page"),
        meta: {
          required: false,
        },
      },

      {
        path: "/:company/login",
        name: "company login",
        component: () => import("../pages/Login.page"),
        meta: {
          required: false,
        },
      },
      {
        path: "/register",
        name: "register",
        component: () => import("../pages/Register.page"),
        meta: {
          required: false,
        },
      },
      {
        path: "/:company/buzon",
        name: "buzon",
        component: () => import("../pages/Buzon.page"),
        meta: {
          required: false,
        },
      },
      {
        path: "/:company/validate",
        name: "validate",
        component: () => import("../pages/Validate.page"),
        meta: {
          required: false,
        },
      },

    ],
  },
  {
    path: "/admin",
    name: "Administador",
    component: Dashboardlayouts,
    meta: {
      required: true,
      roles: ["admin", "superadmin"],
    },
    children: [
      {
        path: "/admin",
        name: "Administador dashboard",
        component: () => import("../pages/admin/Index.page"),
      },
      {
        path: "/admin/import/messages",
        name: "ImportMensajes",
        component: () => import("../pages/admin/ImportMessages.page"),
      },
      {
        path: "/admin/messages",
        name: "Mensajes",
        component: () => import("../pages/admin/Messages.page"),
      },
      {
        path: "/admin/create",
        name: "admincreate",
        component: () => import("../pages/admin/Create.page"),
      },
      {
        path: "/admin/configuracion",
        name: "AdminConfig",
        component: () => import("../pages/admin/Configuration.page"),
      },
      {
        path: "/admin/administracion",
        name: "AdmintrationUser",
        component: () => import("../pages/admin/AdministrationUser.page"),
      },
      {
        path: "/admin/administracion/area",
        name: "Admintration Area",
        meta: {
          required: true,
          roles: ["admin"],
        },
        component: () => import("../pages/admin/AdministrationArea.page"),
      },
      {
        path: "/admin/administracion/report",
        name: "Admintration Report",
        meta: {
          required: true,
          roles: ["admin"],
        },
        component: () => import("../pages/admin/AdministrationReport.page"),
      },
      {
        path: "/admin/configuracion/empresas",
        name: "CompanyConfig",
        meta: {
          required: true,
          roles: ["superadmin"],
        },
        component: () => import("../pages/admin/ConfigurationCompany.page"),
      },
      {
        path: "/admin/configuracion/nuevaempresas",
        name: "NewRegisterCompany",
        component: () => import("../pages/admin/Create.page"),
      },
    ],
  },
];

const router = createRouter({ history: createWebHistory(), routes });
router.beforeEach((to, from, next) => {
  console.log(to.name)
  console.log(to.path)
  if ((to.name === "/login" || to.name === "login") && localStorage.getItem("accessToken")) {
    let user = JSON.parse(localStorage.getItem("user") || null);
    if (user) {
      switch (user.rol) {
        case "user":
          router.push({
            path: `/${user.company ? user.company.slug : ''}/buzon`,
          });
          break;
        case "admin":
          router.push({
            path: "/admin",
          });
          break;
        case "superadmin":
          router.push({
            path: "/admin",
          });
          break;
      }
    }
  }
  else if (to.meta.required) {
    if (!localStorage.getItem("accessToken")) {
      router.push({
        name: "/login",
        query: {
          to: to.name,
        },
      });
    }
    else {
      //   router.push({
      //     name: 'page-not-authorized'
      //   })
      // console.log(to.meta.roles)
      let user = JSON.parse(localStorage.getItem("user") || null);
      if (to.meta.roles) {
        var viewPage = false;
        for (let index = 0; index < to.meta.roles.length; index++) {
          const rol = to.meta.roles[index];
          if (rol == user.rol) {
            viewPage = true;
            break;
          }
        }
        if (!viewPage) {

          router.push({
            path: "/",
          });
        }
      }
      else {
        router.push({
          path: "/",
        });
      }
    }
  }
  next();
  // ...
});
export default router;
