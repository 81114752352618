<template>
    <main class="J-body">
        <HeaderPartial />
        <div class="J-main-container">
            <router-view></router-view>
        </div>

    </main>
</template>
<script>
import HeaderPartial from '../partials/Header.partial.vue'
export default {
    setup() {

    },
    components: {
        HeaderPartial
    }
}
</script>