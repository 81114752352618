<template>
  <main class="J-sidebar-modal" :class="{ show: collapsed }">
    <div class="J-sidebar" :class="{ show: collapsed }">
      <div>
        <div class="J-sidebar-header">
          <div class="J-sidebar-header-bar J-flex">
            <!-- <button class="J-btn J-btn-bars" @click="toggleSidebar">
                <i class="fa-solid fa-xmark"></i>
              </button> -->
          </div>
          <div class="J-flex column center mt-3">
            <div class="J-sidebar-avatar" v-if="users.rol === 'admin'">
              <img :src="photo" class="rounded-circle photo-company" style="aspect-ratio: 1/1; object-fit: cover; object-position: center;" alt="Logo" />
            </div>
            <div class="J-sidebar-avatar" v-else>
              <img src="../../assets/image/favicon-96x96.png" class="rounded-circle" style="aspect-ratio: 1/1; object-fit: cover; object-position: center;" alt="Logo" />
            </div>
            <div style="display: flex; flex-direction: column; text-align: center" class="mt-3">
              <span style="text-transform: uppercase; font-weight: bold" v-if="users && users.rol === 'admin'">
                {{ users.company.name_trade }}
              </span>
              <span v-if="users">{{ users.email }}</span>
            </div>
          </div>
        </div>

        <main v-for="(item, i) of dataMenu" :key="i">
          <nav class="J-sidebar-menu mt-4" v-if="item.roles.includes(users.rol)">
            <ul class="J-sidebar-menu-list">
              <SidebarLink :to="item.link" :icon="item.icon">{{ item.name }}</SidebarLink>
            </ul>
          </nav>
        </main>
      </div>
      <main class="J-sidebar-footer">
        <button class="J-btn J-btn-icon" @click="logout()">
          <i class="fas fa-power-off"></i>
        </button>
      </main>
    </div>
    <div class="J-sidebar-dialog" @click="toggleSidebar()"></div>
  </main>
</template>

<script>
import SidebarLink from "./SidebarLink";
import { UsersStorage } from "@/db/storage";
import { collapsed, toggleSidebar, sidebarWidth } from "./state";

export default {
  props: {},
  components: { SidebarLink },
  data() {
    return {
      usersStorage: new UsersStorage(),
      users: null,
      photo: "",
      dataMenu: [
        {
          name: "Inicio",
          icon: "fas fa-home",
          link: "/admin",
          roles: ["admin", "superadmin"],
        },
        {
          name: "Importar Mensajes",
          icon: "fas fa-comments",
          link: "/admin/import/messages",
          roles: ["superadmin"],
        },
        {
          name: "Mensajes Recibidos",
          icon: "fas fa-comments",
          link: "/admin/messages",
          roles: ["admin", "superadmin"],
        },
        {
          name: "Administración de Usuarios",
          icon: "fas fa-user-gear",
          link: "/admin/administracion",
          roles: ["admin"],
        },
        {
          name: "Opciones",
          icon: "fas fa-gear",
          link: "/admin/configuracion",
          roles: ["admin"],
        },
        {
          name: "Empresas",
          icon: "fas fa-building",
          link: "/admin/configuracion/empresas",
          roles: ["superadmin"],
        },
      ],
    };
  },
  setup() {
    return { collapsed, toggleSidebar, sidebarWidth };
  },
  created() {
    this.users = this.usersStorage.get();
    if (this.users && this.users.company && this.users.company.photo) {
      this.photo = this.users.company.photo.link;
    }
  },
  watch: {
    $route() {
      toggleSidebar();
    },
  },
  methods: {
    logout() {
      this.usersStorage.remove();
      localStorage.removeItem("accessToken");
      window.location = "/";
    },
  },
};
</script>

