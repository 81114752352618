<template>
    <div class="container-fluid JHeader JHeader--fixed">
        <div class="container">
            <header class="JHeader-content">
                <nav class="JNav">
                    <ul v-if="users == null" class="JNav_Menu">
                        <li class="JNav_Menu-item">
                            <router-link to="/login" class="JNav_Menu-item-btn login">
                                Iniciar sesión
                            </router-link>
                        </li>
                    </ul>
                    <ul v-else class="JNav_Menu">
                        <li class="JNav_Menu-item">
                            <router-link :to="users.rol == 'user' ? `/${users.company.slug}/buzon` : '/admin'"
                                class="JNav_Menu-item-link">
                                <span class="fas fa-user"></span>
                            </router-link>
                        </li>
                        <li class="JNav_Menu-item">
                            <a href="javascript:void(0)" @click="logout()" class="JNav_Menu-item-link">
                                <i class="fas fa-sign-in-alt"></i>
                            </a>
                        </li>
                    </ul>
                </nav>
            </header>
        </div>


    </div>
</template>

<script>
import { UsersStorage } from '@/db/storage'
import { collapsed, toggleSidebar, sidebarWidth } from "../sidebar/state";
export default {
    data: () => (
        {
            search: '',
            users: null,
            dataSearch: [],
            pagination: {
                current: 0,
                page: 1,
                pages: 1,
            },
            load: false,
            usersStorage: new UsersStorage(),
        }
    ),
    setup() {
        return { collapsed, toggleSidebar, sidebarWidth };
    },
    computed: {

    },
    mounted() {
        this.users = this.usersStorage.get()
    },
    methods: {
        logout: function () {

            this.usersStorage.remove();
            localStorage.removeItem('accessToken')
            window.location = "/";

        },
    }

}
</script>