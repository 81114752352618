import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "sweetalert2/dist/sweetalert2.min.css";
import VueSweetalert2 from "vue-sweetalert2";
import VueApexCharts from "vue3-apexcharts";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

import "@fontsource/lato/100-italic.css";
import "@fontsource/lato/300-italic.css";
import "@fontsource/lato/400-italic.css";
import "@fontsource/lato/700-italic.css";
import "@fontsource/lato/900-italic.css";

const app = createApp(App);

app.use(VueSweetalert2);
app.component("QuillEditor", QuillEditor);
app.component("apexchart", VueApexCharts);
app.use(router);
app.mount("#app");
