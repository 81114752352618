<template>
  <main>
    <router-view></router-view>
  </main>
</template>

<script>

export default {
  name: "App",
  data: () => ({
  }),
  components: {},
  mounted() {
  },
  methods: {

  },
};
</script>

<style lang="scss">
* {}
</style>
