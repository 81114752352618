<template>
  <!-- <router-link :to="to" class="link" :class="{ active: isActive }"> -->
  <li class="J-sidebar-menu-item">
    <router-link :to="to" class="link">
      <i class="icon" :class="icon" />
      <transition name="fade">
        <!-- <span v-if="!collapsed"> -->
        <span>
          <slot />
        </span>
      </transition>
    </router-link>
  </li>
</template>
<script>
// import { computed } from "vue";
// import { useRoute } from "vue-router";
// import { collapsed } from "./state";
export default {
  props: {
    to: { type: String, required: true },
    icon: { type: String, required: true },
  },
  // setup(props) {
  //   const route = useRoute();
  //   const isActive = computed(() => route.path === props.to);
  //   return { isActive, collapsed };
  // },
};
</script>
