<template>
  <main class="J-body">
    <HeaderPartial />
    <Sidebar />
    <div class="J-main-container">
      <div class="J-flex J-full-vh">
        <router-view></router-view>
      </div>
    </div>
  </main>
</template>
<script>
import Sidebar from "../sidebar/Sidebar.vue";
import HeaderPartial from "../partials/HeaderAdmin.partial.vue";

export default {
  components: {
    Sidebar,
    HeaderPartial,
  },
};
</script>
